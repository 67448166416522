.contact h1 {
  font-size: 50px;
  text-transform: capitalize;
}
.contact p {
  font-size: 30px;
}
.con h3 {
  font-size: 25px;
  color: rgb(60, 13, 168);
}
.con a {
  color: #212b28ab;
  font-weight: bold;
  font-size: 40px;
  border-bottom: 1px solid #106e4bb6;
}
.con a:hover {
  color: #375048ab;
  border-bottom: 1px solid #073d29b6;
}

/*----------------------------- */
/* ------ Form Style --------- */
/*----------------------------- */
.form1 h2 {
  margin-bottom: 0.5%;
  font-size: 150%;
  font-weight: 600;
}
.form1 label {
  margin: 2% 0;
  color: rgba(6, 73, 73, 0.767);
  font-size: 80%;
}
.form1 .col-3 {
  float: left;
  width: 100%;
  margin: 10px 0;
  position: relative;
} /* necessary to give position: relative to parent. */
.form1 input[type="text"] {
  font: 15px/24px "Lato", Arial, sans-serif;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}
.form1 .effect-8 {
  border: 1px solid #ccc;
  padding: 7px 14px 9px;
  transition: 0.4s;
}
.form1 .effect-8 ~ .focus-border:before,
.form1 .effect-8 ~ .focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #4caf50;
  transition: 0.3s;
}
.form1 .effect-8 ~ .focus-border:after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}
.form1 .effect-8 ~ .focus-border i:before,
.form1 .effect-8 ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #4caf50;
  transition: 0.4s;
}
.form1 .effect-8 ~ .focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}
.form1 .effect-8:focus ~ .focus-border:before,
.form1 .effect-8:focus ~ .focus-border:after {
  width: 100%;
  transition: 0.3s;
}
.form1 .effect-8:focus ~ .focus-border i:before,
.form1 .effect-8:focus ~ .focus-border i:after {
  height: 100%;
  transition: 0.4s;
}
.senter {
  text-align: center;
}
.senter h3 {
  text-transform: uppercase;
  font-size: 30px;
  color: rgb(60, 13, 168);
}
.senter h1 {
  font-size: 50px;
}
.senter a {
  color: #0c1110ab;
  font-weight: bold;
  font-size: 40px;
  border-bottom: 1px solid #106e4bb6;
}
.senter a:hover {
  color: #375048ab;
  border-bottom: 1px solid #073d29b6;
}
.add {
  text-align: center;
}
.add p {
  font-size: 18px;
}
@media screen and (max-width: 600px) {
  .contact h1 {
    font-size: 30px;
  }
  .contact p {
    font-size: 20px;
  }
  .senter h1 {
    font-size: 30px;
  }
  .senter a {
    font-size: 23px;
  }
  .con a {
    font-size: 20px;
  }
}

@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Exo", sans-serif;
  overflow-x: hidden;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
  font-family: "Exo", sans-serif;
}
.coming_ {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.coming_ img {
  width: 20%;
  height: auto;
}
.coming_ h1 {
  font-size: 1.5rem;
  font-family: "Pacifico", cursive;

  border-bottom: 1px solid black;
}
@media only screen and (max-width: 600px) {
  .coming_ img {
    width: 35%;
    height: auto;
  }
}

footer {
  border-top: 2px solid #233a85;
  background-color: white;
  margin-top: 20px;
  color: #deece7;
}
footer h2 {
  color: #233a85;
  padding-top: 5px;
  font-size: large;
}
.footBox a {
  padding: 1% 0;
  line-height: 300%;
  font-size: 120%;
  text-transform: uppercase;
  text-decoration: none;

  border-bottom: 1px solid #106e4b;
}
.footBox a:hover {
  color: #deece7ab;
  border-bottom: 1px solid #106e4bb6;
}
.footBox3 {
  display: flex;
  flex-direction: column;
}

.btn {
  width: 150px;
}

.btn1 {
  height: 40px;
  width: 80px;
  border-radius: 5px;
  border: 1px, solid rgba(53, 55, 145, 0.877);
  background-color: #5649cc;
  color: white;
}
.btn1:hover {
  transition: 0.5s;
  background-color: rgb(65, 70, 141);
}
.form2 {
  display: flex;
  width: 100%;
}
.footBox3 p {
  color: #233a85;
}
.form2 input {
  width: 100%;
  text-align: center;
  border-radius: 5px;
  margin-right: 4px;
}

.logo_ {
  background-image: url("/static/media/final logo.2457ee42.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 9vh;
  background-position: center;
  width: 100px;
}
#myTopnav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding-top: 1%;
  overflow-y: hidden;
  z-index: 5;
  min-height: 62px;
}

.topnav {
  opacity: 1;

  background-color: white;
  border-bottom: 2px solid #233a85;
}
.topnav a {
  transition: 0.7s;
  float: right;
  display: block;
  color: #233a85;
  text-align: center;
  padding: 9px 15px;
  margin: 0.1% 1%;
  margin-bottom: 1.3%;
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 17px;
}
.topnav a:first-child {
  float: left;
  padding: 0;
  margin: 0;
}
.topnav ul {
  list-style: none;
}
#con {
  color: white;
  border-radius: 3rem;
  border: 0.5px solid white;
  transition: background-color 0.7s;
  font-weight: 600;
  background-color: rgba(5, 7, 126, 0.8);
}
#con.active {
  background-color: #23a5de;
  color: white;
  transition: 0.3s;
  font-weight: 600;
}
#con:hover {
  background-color: #23a5de;
  color: white;
  transition: 0.3s;
  font-weight: 600;
}
.topnav a:hover {
  transition: 0.5s;
  color: #23a5de;
}

.topnav a.active {
  color: #23a5de;
}

.topnav .icon {
  display: none;
}
.topnav.responsive {
  /* transition: all 1s ease-in-out;
  transition-duration: 1s; */
  /* position: absolute;
  width: 100%;
  display: block;
  
  height: 0;
  overflow: hidden; */
  transition: height 1s ease;
  height: 0;
}

@media screen and (max-width: 900px) and (min-width: 600px) {
  .topnav a {
    margin-top: 0.35%;
  }
}
@media screen and (max-width: 600px) {
  .topnav a:first-child {
    float: left;
    z-index: 1;
  }
  .topnav {
    z-index: 1;
    height: 41px;
    transition: height 0.6s;
    padding-bottom: 8px;
  }
  .topnav a:not(:first-child) {
    display: none;
    transition: 1s display;
  }
  .topnav a:nth-child(2),
  .topnav a:nth-child(3),
  .topnav a:nth-child(4),
  .topnav a:nth-child(5),
  #con {
    visibility: hidden;
    -webkit-animation: 0.5s fadeIn;
            animation: 0.5s fadeIn;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }

  .topnav.responsive {
    width: 100%;

    height: 250px;

    position: absolute;

    opacity: 0.92;
    display: block;
  }

  .topnav.responsive .a {
    visibility: visible;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 2.3%;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  #myTopnav {
    padding-top: 2%;
    min-height: 40px;
  }
  .logo_ {
    height: 47px;
  }
  .topnav.topnav.responsive #con {
    margin-top: 10px;
    color: white;
    border: 0;
  }
}
@-webkit-keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.contact h1 {
  font-size: 50px;
  text-transform: capitalize;
}
.contact p {
  font-size: 30px;
}
.con h3 {
  font-size: 25px;
  color: rgb(60, 13, 168);
}
.con a {
  color: #212b28ab;
  font-weight: bold;
  font-size: 40px;
  border-bottom: 1px solid #106e4bb6;
}
.con a:hover {
  color: #375048ab;
  border-bottom: 1px solid #073d29b6;
}

/*----------------------------- */
/* ------ Form Style --------- */
/*----------------------------- */
.form1 h2 {
  margin-bottom: 0.5%;
  font-size: 150%;
  font-weight: 600;
}
.form1 label {
  margin: 2% 0;
  color: rgba(6, 73, 73, 0.767);
  font-size: 80%;
}
.form1 .col-3 {
  float: left;
  width: 100%;
  margin: 10px 0;
  position: relative;
} /* necessary to give position: relative to parent. */
.form1 input[type="text"] {
  font: 15px/24px "Lato", Arial, sans-serif;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}
.form1 .effect-8 {
  border: 1px solid #ccc;
  padding: 7px 14px 9px;
  transition: 0.4s;
}
.form1 .effect-8 ~ .focus-border:before,
.form1 .effect-8 ~ .focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #4caf50;
  transition: 0.3s;
}
.form1 .effect-8 ~ .focus-border:after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}
.form1 .effect-8 ~ .focus-border i:before,
.form1 .effect-8 ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #4caf50;
  transition: 0.4s;
}
.form1 .effect-8 ~ .focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}
.form1 .effect-8:focus ~ .focus-border:before,
.form1 .effect-8:focus ~ .focus-border:after {
  width: 100%;
  transition: 0.3s;
}
.form1 .effect-8:focus ~ .focus-border i:before,
.form1 .effect-8:focus ~ .focus-border i:after {
  height: 100%;
  transition: 0.4s;
}
.senter {
  text-align: center;
}
.senter h3 {
  text-transform: uppercase;
  font-size: 30px;
  color: rgb(60, 13, 168);
}
.senter h1 {
  font-size: 50px;
}
.senter a {
  color: #0c1110ab;
  font-weight: bold;
  font-size: 40px;
  border-bottom: 1px solid #106e4bb6;
}
.senter a:hover {
  color: #375048ab;
  border-bottom: 1px solid #073d29b6;
}
.add {
  text-align: center;
}
.add p {
  font-size: 18px;
}
@media screen and (max-width: 600px) {
  .contact h1 {
    font-size: 30px;
  }
  .contact p {
    font-size: 20px;
  }
  .senter h1 {
    font-size: 30px;
  }
  .senter a {
    font-size: 23px;
  }
  .con a {
    font-size: 20px;
  }
}

.cont {
  padding: 7% 15%;
}
.cont h1 {
  text-transform: capitalize;
  font-size: 50px;
}
.cont p {
  font-size: 20px;
  line-height: 170%;
}
.vlog p {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: white;
}
.vlog {
  padding: 2.5% 6%;
  border: 1px solid rgba(0, 0, 0, 0.055);
  border-radius: 5px;
  position: relative;
  transition: all 0.5s;
  height: 200px;
}
.masterbox .box1 .vlog {
  background-color: #23a5de;
  margin: 1% 2%;
}
.vlog:hover {
  transform: scale(1.01, 1.05);
  box-shadow: 10px 10px 35px -8px rgba(0, 0, 0, 0.75);
}
.mix {
  background-image: url(/static/media/interior.675ecc24.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  height: 80vh;
}
.hov {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  padding: 7% 6%;
  background-color: rgba(5, 7, 126, 0.5);
  color: white;
  position: absolute;
  z-index: 1;
  left: 40%;
  right: 7%;
}

.startup {
  text-align: center;
  padding: 0% 10%;
}
.startup h1 {
  font-size: 50px;
}
.contain {
  border-radius: 2rem;
  padding: 0 3.5%;
  background-color: #23a5de;
}
.original-div {
  background-image: url(/static/media/logo.3037dc66.jpeg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 40vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "overlap";
  text-align: center;
}
.uper_ {
  border-radius: 10px;
  padding: 5% 5% 25% 25%;
  width: 100%;
  height: 100%;
  position: absolute;
  /* top: 0; left: 0; bottom: 0; right: 0; */
  z-index: -1;
  opacity: 0.3;
}
li {
  text-align: start;
}
.back-div {
  background-color: rgb(47, 47, 65);
  z-index: 1;
  grid-area: "overlap";
  width: 100%;
  opacity: 0.9;

  overflow-x: hidden;
}
.overlay-div {
  color: white;
  padding: 15vh 0;
  margin: auto;
  z-index: 3;
  width: 50%;
  grid-area: "overlap";
}
.row_ {
  flex: 1 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.pservice {
  overflow-x: hidden;
  border: 2px solid #233a85;
  border-radius: 10px;
  width: 15%;
  padding: 1% 1%;
  margin: 1% 1%;
  color: #233a85;
  text-align: center;
  background-color: white;
  transition: all 0.3s;
}
.pservice:hover {
  color: #23a5de;
  transform: scale(1.05, 1.05);
  transition: all 0.3s;
  box-shadow: 10px 10px 35px -8px rgba(0, 0, 0, 0.75);
}

.icon_ {
  color: rgb(3, 12, 12);
}
#size_ {
  font-size: 170px;
}
.pservice #size_2 {
  font-size: 15vh;
  margin: 0;
  padding: 0;
}
.startup p {
  font-size: 20px;
}
.ptext p {
  display: inline;
  transition: 0.3s;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 20px;
}
.icon_2 {
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 900px) {
  .vlog p {
    font-size: 15px;
  }
  .pservice {
    width: 18%;
    height: auto;
    padding-bottom: 1%;
  }
  .pservice p {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .pservice {
    width: 30%;
  }
  .pservice {
    width: 30%;
  }
  .pservice #size_2 {
    font-size: 15vw;
  }
  .cont {
    padding: 7% 2%;
  }
  .hov {
    margin-top: 0;
    padding-bottom: 0;
  }
  .startup h1 {
    font-size: 30px;
  }
  .hov p {
    font-size: 15px;
  }
  .vlog p {
    font-size: 20px;
    padding: 2% 4%;
  }
  #size_ {
    font-size: 80px;
  }
  .uper_ {
    padding: 38% 0 35% 30%;
  }
  .vlog {
    height: auto;
    margin: 0.5% 8%;
  }
  .masterbox .box1:not(:last-child) .vlog {
    border-bottom: 1px solid #00000077;
  }

  .startup p {
    font-size: 20px;
  }
  .overlay-div {
    width: 75%;
  }
}
@media screen and (max-width: 900px) {
  .overlay-div {
    width: 70%;
  }
}

.sample {
  margin: 1% 5%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 90%;
}
.tas {
  margin: 2% 5%;
  border-radius: 3rem;
  background-image: url("https://www.pikpng.com/pngl/m/243-2439658_customer-service-icon-png-transparent-customer-icon-png.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 300px;
}
.tas1 {
  margin: 2% 5%;
  border-radius: 3rem;
  background-image: url("https://www.pngitem.com/pimgs/m/207-2071237_customer-service-icon-customer-service-flat-icon-png.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 300px;
}
.eff {
  transition: 0.7s;
}
.eff:hover {
  transform: scale(1.05, 1.05);
}
.text_ {
  font-family: "Overlock", cursive;
  color: white;
  text-align: center;
}
.text_ p {
  font-size: 20px;
}
.stars {
  margin: auto;
}
@media only screen and (max-width: 900px) {
  .tas {
    background-position: center;
  }
  .tas1 {
    background-position: center;
  }
}
@media only screen and (max-width: 600px) {
  .sample {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .tas {
    background-position: center;
  }
  .tas1 {
    background-position: center;
  }
}

/* ------------------------- */
/* ------- Header ---------- */
/* ------------------------- */

.header {
  color: #fff;
  padding: 4% 15%;
  align-items: center;
}
.header h2 {
  text-transform: uppercase;
}
.header h1 {
  font-size: 50px;
}
.header p {
  font-size: 20px;
}
.header div {
  padding: 3% 10% 5% 5%;
  background-color: rgba(39, 39, 54, 0.5);
  border-radius: 20px;
}
.header a {
  text-transform: uppercase;
  font-size: 25px;
  margin-bottom: 3px;
  text-decoration: none;
  color: #fff;
  border-bottom: 2px solid #fff;
}
img {
  border-radius: 1rem;
}
.header a:hover {
  color: #aaa;
  border-bottom: 2px solid #aaa;
}
.pic {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3785889355742297) 0%,
      rgba(250, 250, 250, 0.04245448179271705) 52%,
      rgba(0, 0, 0, 0.4822303921568627) 100%
    ),
    url(/static/media/mountain.48739d99.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 650px;
}
img {
  height: 20vw;
}
.para {
  padding: 2% 20%;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card div {
  margin-top: 5px;
}

.pictures {
  text-align: center;
  padding: 2%;
}
.revamp {
  height: 40px;
  padding: 7px 1.5vw;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #233a85;
  transition: all 0.5s;
  font-weight: 700;
  color: rgba(5, 7, 126, 0.95);
}
.revamp:hover {
  transition: 0.5s;
  color: white;
  background-color: #233a85;
}
.pic_ {
  margin: 1%;
  width: 50%;
  height: auto;
}
.pic1_ {
  margin: 1%;
  width: 12%;
  height: auto;
}
@media screen and (max-width: 600px) {
  .header {
    padding: 3%;
  }
  .para {
    padding: 0;
  }
  .pictures {
    padding: 0%;
  }
  .header h1 {
    font-size: 30px;
  }
  .header h2 {
    font-size: 15px;
  }
  .header a {
    font-size: 20px;
  }
  img {
    height: 40vw;
  }
}

.intro {
  padding: 2% 3%;
}
.intro h1 {
  font-size: 40px;
}
.intro p {
  font-size: 20px;
}
.mix1 {
  background-image: url("https://thelawpracticeexchange.com/wp-content/uploads/2018/10/selling-law.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 65vh;
}
.mix2 {
  background-position: right;
  background-image: url("https://blog.hubspot.com/hubfs/marketing-agency-firm.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 60vh;
}
.hov1 {
  background-color: rgba(5, 7, 126, 0.5);
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  padding: 3vh 2vw;
  color: white;
  position: absolute;
  z-index: 1;
  left: 40%;
  right: 7%;
}
.hov2 {
  background-color: rgba(5, 7, 126, 0.5);
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  padding: 3vh 2vw;
  color: white;
  position: absolute;
  z-index: 1;
  left: 7%;
  right: 40%;
}
.hov h2 {
  color: cadetblue;
}
.max {
  padding: 5% 5%;
}
.shashka {
  background-image: url(/static/media/creator.3d1ec164.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  height: 300px;
}

.last {
  text-align: center;
}
.last h1 {
  font-size: 50px;
}

.tareef h3 {
  color: cadetblue;
}
.tareef h2 {
  color: rgba(147, 151, 151, 0.863);
  font-size: 30px;
}
@media screen and (max-width: 700px) {
  .padd_ {
    margin-top: 15%;
  }
}
@media screen and (max-width: 600px) {
  .padd_ {
    margin-top: 8%;
  }
  .mix1 {
    height: 45vh;
    margin: 0;
    background-size: cover;
    /* background-position: center; */
    padding: 0;
  }
  .mix2 {
    height: 45vh;
    margin: 0;
    background-size: cover;
    padding: 0;
  }
  .intro h1 {
    font-size: 30px;
  }
  .intro p {
    font-size: 15px;
  }
  .hov1 {
    /* margin-top: 0;
    right: 3%; */
    position: static;
  }
  .hov2 {
    /* margin-top: 0;
    right: 3%; */
    position: static;
  }
  .shashka {
    background-position: center;
  }
  .last h1 {
    font-size: 30px;
  }
  .hov1 p {
    font-size: 15px;
  }
  .hov2 p {
    font-size: 15px;
  }
}


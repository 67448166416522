.sample {
  margin: 1% 5%;
  height: fit-content;
  width: 90%;
}
.tas {
  margin: 2% 5%;
  border-radius: 3rem;
  background-image: url("https://www.pikpng.com/pngl/m/243-2439658_customer-service-icon-png-transparent-customer-icon-png.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 300px;
}
.tas1 {
  margin: 2% 5%;
  border-radius: 3rem;
  background-image: url("https://www.pngitem.com/pimgs/m/207-2071237_customer-service-icon-customer-service-flat-icon-png.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 300px;
}
.eff {
  transition: 0.7s;
}
.eff:hover {
  transform: scale(1.05, 1.05);
}
.text_ {
  font-family: "Overlock", cursive;
  color: white;
  text-align: center;
}
.text_ p {
  font-size: 20px;
}
.stars {
  margin: auto;
}
@media only screen and (max-width: 900px) {
  .tas {
    background-position: center;
  }
  .tas1 {
    background-position: center;
  }
}
@media only screen and (max-width: 600px) {
  .sample {
    height: fit-content;
  }
  .tas {
    background-position: center;
  }
  .tas1 {
    background-position: center;
  }
}

.logo_ {
  background-image: url("../backgrounds/final\ logo.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 9vh;
  background-position: center;
  width: 100px;
}
#myTopnav {
  position: sticky;
  top: 0;
  padding-top: 1%;
  overflow-y: hidden;
  z-index: 5;
  min-height: 62px;
}

.topnav {
  opacity: 1;

  background-color: white;
  border-bottom: 2px solid #233a85;
}
.topnav a {
  transition: 0.7s;
  float: right;
  display: block;
  color: #233a85;
  text-align: center;
  padding: 9px 15px;
  margin: 0.1% 1%;
  margin-bottom: 1.3%;
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 17px;
}
.topnav a:first-child {
  float: left;
  padding: 0;
  margin: 0;
}
.topnav ul {
  list-style: none;
}
#con {
  color: white;
  border-radius: 3rem;
  border: 0.5px solid white;
  transition: background-color 0.7s;
  font-weight: 600;
  background-color: rgba(5, 7, 126, 0.8);
}
#con.active {
  background-color: #23a5de;
  color: white;
  transition: 0.3s;
  font-weight: 600;
}
#con:hover {
  background-color: #23a5de;
  color: white;
  transition: 0.3s;
  font-weight: 600;
}
.topnav a:hover {
  transition: 0.5s;
  color: #23a5de;
}

.topnav a.active {
  color: #23a5de;
}

.topnav .icon {
  display: none;
}
.topnav.responsive {
  /* transition: all 1s ease-in-out;
  transition-duration: 1s; */
  /* position: absolute;
  width: 100%;
  display: block;
  
  height: 0;
  overflow: hidden; */
  -webkit-transition: height 1s ease;
  -moz-transition: height 1s ease;
  -o-transition: height 1s ease;
  transition: height 1s ease;
  height: 0;
}

@media screen and (max-width: 900px) and (min-width: 600px) {
  .topnav a {
    margin-top: 0.35%;
  }
}
@media screen and (max-width: 600px) {
  .topnav a:first-child {
    float: left;
    z-index: 1;
  }
  .topnav {
    z-index: 1;
    height: 41px;
    transition: height 0.6s;
    padding-bottom: 8px;
  }
  .topnav a:not(:first-child) {
    display: none;
    transition: 1s display;
  }
  .topnav a:nth-child(2),
  .topnav a:nth-child(3),
  .topnav a:nth-child(4),
  .topnav a:nth-child(5),
  #con {
    visibility: hidden;
    animation: 0.5s fadeIn;
    animation-fill-mode: forwards;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }

  .topnav.responsive {
    width: 100%;

    height: 250px;

    position: absolute;

    opacity: 0.92;
    display: block;
  }

  .topnav.responsive .a {
    visibility: visible;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 2.3%;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  #myTopnav {
    padding-top: 2%;
    min-height: 40px;
  }
  .logo_ {
    height: 47px;
  }
  .topnav.topnav.responsive #con {
    margin-top: 10px;
    color: white;
    border: 0;
  }
}
@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

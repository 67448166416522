/* ------------------------- */
/* ------- Header ---------- */
/* ------------------------- */

.header {
  color: #fff;
  padding: 4% 15%;
  align-items: center;
}
.header h2 {
  text-transform: uppercase;
}
.header h1 {
  font-size: 50px;
}
.header p {
  font-size: 20px;
}
.header div {
  padding: 3% 10% 5% 5%;
  background-color: rgba(39, 39, 54, 0.5);
  border-radius: 20px;
}
.header a {
  text-transform: uppercase;
  font-size: 25px;
  margin-bottom: 3px;
  text-decoration: none;
  color: #fff;
  border-bottom: 2px solid #fff;
}
img {
  border-radius: 1rem;
}
.header a:hover {
  color: #aaa;
  border-bottom: 2px solid #aaa;
}
.pic {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3785889355742297) 0%,
      rgba(250, 250, 250, 0.04245448179271705) 52%,
      rgba(0, 0, 0, 0.4822303921568627) 100%
    ),
    url("../backgrounds/mountain.jpg");
  background-size: cover;
  background-attachment: fixed;
  height: 650px;
}
img {
  height: 20vw;
}
.para {
  padding: 2% 20%;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card div {
  margin-top: 5px;
}

.pictures {
  text-align: center;
  padding: 2%;
}
.revamp {
  height: 40px;
  padding: 7px 1.5vw;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #233a85;
  transition: all 0.5s;
  font-weight: 700;
  color: rgba(5, 7, 126, 0.95);
}
.revamp:hover {
  transition: 0.5s;
  color: white;
  background-color: #233a85;
}
.pic_ {
  margin: 1%;
  width: 50%;
  height: auto;
}
.pic1_ {
  margin: 1%;
  width: 12%;
  height: auto;
}
@media screen and (max-width: 600px) {
  .header {
    padding: 3%;
  }
  .para {
    padding: 0;
  }
  .pictures {
    padding: 0%;
  }
  .header h1 {
    font-size: 30px;
  }
  .header h2 {
    font-size: 15px;
  }
  .header a {
    font-size: 20px;
  }
  img {
    height: 40vw;
  }
}

footer {
  border-top: 2px solid #233a85;
  background-color: white;
  margin-top: 20px;
  color: #deece7;
}
footer h2 {
  color: #233a85;
  padding-top: 5px;
  font-size: large;
}
.footBox a {
  padding: 1% 0;
  line-height: 300%;
  font-size: 120%;
  text-transform: uppercase;
  text-decoration: none;

  border-bottom: 1px solid #106e4b;
}
.footBox a:hover {
  color: #deece7ab;
  border-bottom: 1px solid #106e4bb6;
}
.footBox3 {
  display: flex;
  flex-direction: column;
}

.btn {
  width: 150px;
}

.btn1 {
  height: 40px;
  width: 80px;
  border-radius: 5px;
  border: 1px, solid rgba(53, 55, 145, 0.877);
  background-color: #5649cc;
  color: white;
}
.btn1:hover {
  transition: 0.5s;
  background-color: rgb(65, 70, 141);
}
.form2 {
  display: flex;
  width: 100%;
}
.footBox3 p {
  color: #233a85;
}
.form2 input {
  width: 100%;
  text-align: center;
  border-radius: 5px;
  margin-right: 4px;
}

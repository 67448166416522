.cont {
  padding: 7% 15%;
}
.cont h1 {
  text-transform: capitalize;
  font-size: 50px;
}
.cont p {
  font-size: 20px;
  line-height: 170%;
}
.vlog p {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: white;
}
.vlog {
  padding: 2.5% 6%;
  border: 1px solid rgba(0, 0, 0, 0.055);
  border-radius: 5px;
  position: relative;
  transition: all 0.5s;
  height: 200px;
}
.masterbox .box1 .vlog {
  background-color: #23a5de;
  margin: 1% 2%;
}
.vlog:hover {
  transform: scale(1.01, 1.05);
  -webkit-box-shadow: 10px 10px 35px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 35px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 35px -8px rgba(0, 0, 0, 0.75);
}
.mix {
  background-image: url("../backgrounds/interior.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 80vh;
}
.hov {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  padding: 7% 6%;
  background-color: rgba(5, 7, 126, 0.5);
  color: white;
  position: absolute;
  z-index: 1;
  left: 40%;
  right: 7%;
}

.startup {
  text-align: center;
  padding: 0% 10%;
}
.startup h1 {
  font-size: 50px;
}
.contain {
  border-radius: 2rem;
  padding: 0 3.5%;
  background-color: #23a5de;
}
.original-div {
  background-image: url("../backgrounds/logo.jpeg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 40vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "overlap";
  text-align: center;
}
.uper_ {
  border-radius: 10px;
  padding: 5% 5% 25% 25%;
  width: 100%;
  height: 100%;
  position: absolute;
  /* top: 0; left: 0; bottom: 0; right: 0; */
  z-index: -1;
  opacity: 0.3;
}
li {
  text-align: start;
}
.back-div {
  background-color: rgb(47, 47, 65);
  z-index: 1;
  grid-area: "overlap";
  width: 100%;
  opacity: 0.9;

  overflow-x: hidden;
}
.overlay-div {
  color: white;
  padding: 15vh 0;
  margin: auto;
  z-index: 3;
  width: 50%;
  grid-area: "overlap";
}
.row_ {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.pservice {
  overflow-x: hidden;
  border: 2px solid #233a85;
  border-radius: 10px;
  width: 15%;
  padding: 1% 1%;
  margin: 1% 1%;
  color: #233a85;
  text-align: center;
  background-color: white;
  transition: all 0.3s;
}
.pservice:hover {
  color: #23a5de;
  transform: scale(1.05, 1.05);
  transition: all 0.3s;
  -webkit-box-shadow: 10px 10px 35px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 35px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 35px -8px rgba(0, 0, 0, 0.75);
}

.icon_ {
  color: rgb(3, 12, 12);
}
#size_ {
  font-size: 170px;
}
.pservice #size_2 {
  font-size: 15vh;
  margin: 0;
  padding: 0;
}
.startup p {
  font-size: 20px;
}
.ptext p {
  display: inline;
  transition: 0.3s;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 20px;
}
.icon_2 {
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 900px) {
  .vlog p {
    font-size: 15px;
  }
  .pservice {
    width: 18%;
    height: auto;
    padding-bottom: 1%;
  }
  .pservice p {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .pservice {
    width: 30%;
  }
  .pservice {
    width: 30%;
  }
  .pservice #size_2 {
    font-size: 15vw;
  }
  .cont {
    padding: 7% 2%;
  }
  .hov {
    margin-top: 0;
    padding-bottom: 0;
  }
  .startup h1 {
    font-size: 30px;
  }
  .hov p {
    font-size: 15px;
  }
  .vlog p {
    font-size: 20px;
    padding: 2% 4%;
  }
  #size_ {
    font-size: 80px;
  }
  .uper_ {
    padding: 38% 0 35% 30%;
  }
  .vlog {
    height: auto;
    margin: 0.5% 8%;
  }
  .masterbox .box1:not(:last-child) .vlog {
    border-bottom: 1px solid #00000077;
  }

  .startup p {
    font-size: 20px;
  }
  .overlay-div {
    width: 75%;
  }
}
@media screen and (max-width: 900px) {
  .overlay-div {
    width: 70%;
  }
}

.intro {
  padding: 2% 3%;
}
.intro h1 {
  font-size: 40px;
}
.intro p {
  font-size: 20px;
}
.mix1 {
  background-image: url("https://thelawpracticeexchange.com/wp-content/uploads/2018/10/selling-law.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 65vh;
}
.mix2 {
  background-position: right;
  background-image: url("https://blog.hubspot.com/hubfs/marketing-agency-firm.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 60vh;
}
.hov1 {
  background-color: rgba(5, 7, 126, 0.5);
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  padding: 3vh 2vw;
  color: white;
  position: absolute;
  z-index: 1;
  left: 40%;
  right: 7%;
}
.hov2 {
  background-color: rgba(5, 7, 126, 0.5);
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  padding: 3vh 2vw;
  color: white;
  position: absolute;
  z-index: 1;
  left: 7%;
  right: 40%;
}
.hov h2 {
  color: cadetblue;
}
.max {
  padding: 5% 5%;
}
.shashka {
  background-image: url("../backgrounds/creator.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 300px;
}

.last {
  text-align: center;
}
.last h1 {
  font-size: 50px;
}

.tareef h3 {
  color: cadetblue;
}
.tareef h2 {
  color: rgba(147, 151, 151, 0.863);
  font-size: 30px;
}
@media screen and (max-width: 700px) {
  .padd_ {
    margin-top: 15%;
  }
}
@media screen and (max-width: 600px) {
  .padd_ {
    margin-top: 8%;
  }
  .mix1 {
    height: 45vh;
    margin: 0;
    background-size: cover;
    /* background-position: center; */
    padding: 0;
  }
  .mix2 {
    height: 45vh;
    margin: 0;
    background-size: cover;
    padding: 0;
  }
  .intro h1 {
    font-size: 30px;
  }
  .intro p {
    font-size: 15px;
  }
  .hov1 {
    /* margin-top: 0;
    right: 3%; */
    position: static;
  }
  .hov2 {
    /* margin-top: 0;
    right: 3%; */
    position: static;
  }
  .shashka {
    background-position: center;
  }
  .last h1 {
    font-size: 30px;
  }
  .hov1 p {
    font-size: 15px;
  }
  .hov2 p {
    font-size: 15px;
  }
}
